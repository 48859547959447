import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Link from "@material-ui/core/Link"
import Paper from "@material-ui/core/Paper"
import Typography, { TypographyProps } from "@material-ui/core/Typography"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { OverviewArray } from ".."
import useAvatar from "../../../../hooks/useAvatar"

interface IProps {
  data: OverviewArray
}

const breakpoint = "780px"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: ${({ theme }) => `${theme.spacing(8)}px 0`};

  @media (max-width: ${breakpoint}) {
    flex-direction: column;
  }
`

const Img = styled(GatsbyImage)`
  aspect-ratio: 1;
  position: relative;
  z-index: 1;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows[3]};
  margin-right: ${({ theme }) => theme.spacing(-6)}px;

  width: 100%;
  max-width: 478px;

  @media (max-width: ${breakpoint}) {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: ${({ theme }) => theme.spacing(-4)}px;
  }

  img {
    object-fit: cover !important;
  }
`

const Text = styled(Paper)`
  position: relative;
  z-index: 2;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(-6)}px;
  max-width: 686px;
  height: fit-content;
  padding: ${({ theme }) =>
    `${theme.spacing(5)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`};

  @media (max-width: ${breakpoint}) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`

const Header = styled(props => <Typography {...props} />)<TypographyProps<"p">>`
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.spacing(-0.5)}px;
`

const Description = styled(props => <Typography {...props} />)<
  TypographyProps<"div">
>`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

const Contact = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  > * + * {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`

const Spacer = styled(Divider)`
  margin: ${({ theme }) => `${theme.spacing(1)}px 0`};
  background: ${({ theme }) => theme.palette.primary.light};
  width: 200px;
`

const Large: React.FC<IProps> = ({ data }) => {
  const avatar = useAvatar()
  return (
    <>
      {data.map(item => {
        if (item?.__typename !== "WpVrijwilliger") return null
        const { afbeelding, info } = { ...item.vrijwilliger?.vrijwilliger }
        const { weergaveNaam, omschrijving, eMail, telefoon, url } = { ...info }

        const img =
          afbeelding?.localFile?.childImageSharp?.gatsbyImageData || avatar
        const alt = afbeelding?.altText || info?.weergaveNaam || "medewerker"

        return (
          <Wrapper key={item.id}>
            <Img image={img} alt={alt} />
            <Text elevation={7}>
              <Header variant="h3" color="primary" component="p">
                {weergaveNaam}
              </Header>
              <Typography variant="body2" color="secondary" component="p">
                {omschrijving?.toLocaleUpperCase()}
              </Typography>

              <Spacer />

              <Contact>
                {eMail && (
                  <>
                    <Link color="secondary" href={`mailto:${eMail}`}>
                      {eMail}
                    </Link>
                    {telefoon && (
                      <Typography color="primary" component="span">
                        {" "}
                        |{" "}
                      </Typography>
                    )}
                  </>
                )}

                {telefoon && <Link color="secondary">{telefoon}</Link>}
              </Contact>

              <Description
                variant="body1"
                component="div"
                dangerouslySetInnerHTML={{
                  __html: item.vrijwilliger?.omschrijving || "",
                }}
              />

              {url?.url && (
                <Button
                  component="a"
                  variant="outlined"
                  color="primary"
                  href={url?.url}
                  target={url?.target || undefined}
                >
                  {url?.title}
                </Button>
              )}
            </Text>
          </Wrapper>
        )
      })}
    </>
  )
}

export default Large
